/**
 * Takes contentful publishDate and formats it correctly
 * @param {String} publishDate
 * @returns {String} formatted date string
 */
function formatPublishDate(publishDate) {
  if (!publishDate) return ''
  const [year, month, day] = publishDate.split('T')[0].split('-')
  const dateObject = new Date(year, month - 1, day)
  const dateString = `${dateObject.toLocaleString('en-US', { month: 'long' })} ${day}, ${year}`
  return dateString
}

export { formatPublishDate }
